body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
  padding: 5px;
  background-color: #a26c6c;
  color: white;
}

.yearAlbum {
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
}

.album {
  padding: 5px;
  font-size: 16px;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.choices {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  cursor: pointer;
  border-bottom: 2px white solid;
  font-size: 20px;
}

.albums-choice {
  padding: 15px;
}

.aphorism-choice {
  margin-left: 50px;
  padding: 15px;
}

.selected-choice {
  color: #a26c6c;
  background-color: white;
  border: 2px white solid;
  border-bottom: none;
}

.unselected-choice {
  color: white;
  background-color: #a26c6c;
  border: 2px white solid;
  border-bottom: none;
}

.unselected-choice:hover {
  background-color: #845353;
}

.aphorism-header {
  display: flex;
  flex-direction: horizontal;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.aphorism {
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
}

.aphorism-content {
  margin-top: 10px;
}

.aphorism-date {
  font-size: 10px;
}
